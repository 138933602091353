import React, {useEffect, useState} from 'react';
import {Navigate, Outlet, useParams} from 'react-router-dom';
import LoadingUser from '../Components/LoadingUser/LoadingUser';
// import Sidebar from '../Components/Sidebar/Sidebar';
// import useApplicationData from '../Hooks/useApplicationData';

const Dashboard = ({state, dispatch, wsSocket}) => {
  const params = useParams();
  const {initialized, isConnected, didConnect, groups, groupCollection, loadingGroups, scannersData} = state;

  useEffect(() => {
    if (!loadingGroups) {
      return;
    }
    const joinedGroups = [...(groups ?? [])].filter(
      (gr) => gr.joined && Object.keys(groupCollection || {})?.includes(gr.group),
    );
    if (
      groups?.length === Object.keys(groupCollection || {}).length &&
      groups?.length &&
      Object.keys(groupCollection || {}).length &&
      joinedGroups.length &&
      loadingGroups
    ) {
      dispatch({type: 'SET_CONNECTED_STATUS'});
    }
  }, [groupCollection, groups]);

  return initialized ? (
    isConnected ? (
      <div className="flex w-full">
        {/* <Sidebar scannersData={scannersData} theme={theme} setTheme={setTheme} /> */}
        <div
          className={`${
            scannersData?.find((s) => s.slug === params?.id)?.layout === 'sidebar-layout'
              ? ' flex-grow w-full relative main-container'
              : 'flex-grow w-5/6 pt-4 px-4 relative main-container'
          } `}
        >
          <Outlet context={[state, dispatch, wsSocket]} />
          <div className="powered-by">
            <a href="https://f2-tech.com/" target="_blank" rel="noreferrer">
              Powered By F2Tech
            </a>
          </div>
        </div>
      </div>
    ) : didConnect ? (
      <LoadingUser message="Reconnecting..." />
    ) : (
      <Navigate replace to="/access-denied" />
    )
  ) : (
    <LoadingUser />
  );
};

export default Dashboard;
