import React, {useState, useEffect} from 'react';
// eslint-disable-next-line import/no-unresolved
import {useWorkerizedReducer} from 'use-workerized-reducer/react';
import {io} from 'socket.io-client';
import ReactDOM from 'react-dom/client';
import {Routes, Route, useParams, useLocation} from 'react-router-dom';
import {applyTheme} from './themes/utils';
import Dashboard from './Pages/Dashboard';
import Home from './Pages/Home';
import Scanners from './Pages/Scanners';
import AccessDenied from './Pages/AccessDenied';
import Logout from './Pages/Logout';
import NotFound from './Pages/NotFound';
import {createStateViewCSS, setupGroups, join} from './Helpers/formatData';
import Reports from './ScannerViews/Reports';
import Report from './Components/Reports/Report';

import './App.css';

const {REACT_APP_STANDALONE} = process.env;

const w = new Worker(new URL('./worker.js', import.meta.url), {
  type: 'module',
});
window.w = w;

const initialState = {
  groupCollection: {},
  groups: null,
  isConnected: false,
  didConnect: false,
  loadingGroups: true,
  initialized: false,
  updatedCount: 0,
  chartSettings: {},
  showChart: false,
  symbolData: {},
  symbolToChart: null,
  groupToChart: null,
  secondaryGroups: [],
  optionToView: null,
  inView: 0,
  reports: {},
  currentDate: null,
};


// const liveURL = 'http://localhost:3000/scanners' ?? process.env.REACT_APP_LIVE_URL ?? 'https://as-live.f2-tech.com/scanners';
// const delayedURL = 'http://localhost:3000/scanners' ?? process.env.REACT_APP_DELAYED_URL ?? 'https://as-dlyd.f2-tech.com/scanners';
const liveURL = process.env.REACT_APP_LIVE_URL ?? 'https://as-live.f2-tech.com/scanners';
const delayedURL = process.env.REACT_APP_DELAYED_URL ?? 'https://as-dlyd.f2-tech.com/scanners';

const App = () => {
  const params = useParams();
  const location = useLocation();
  const [theme, setTheme] = useState(localStorage.getItem('theme') ?? 'light');
  const [wsSocket, setWsSocket] = useState(null);
  const [state, dispatch] = useWorkerizedReducer(w, 'mainReducer', initialState);

  useEffect(() => {
    console.log('theme', theme);
    applyTheme(theme);
  }, [theme]);

  // Set up the websocket connection
  useEffect(() => {
    const url = new URL(window.location.href);
    let token = url.searchParams.get('token');
    if (token) {
      localStorage.setItem('scanner-sso', token);
    } else {
      token = localStorage.getItem('scanner-sso');
    }
    if (token && !wsSocket) {
      const socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
        reconnectionDelayMax: 5000,
        auth: {
          token: localStorage.getItem('scanner-sso'),
        },
        transports: ['websocket', 'polling'],
      });
      setWsSocket(socket);
    }
  }, []);

  const reconnectSocket = () => {
    const acc = [{year: 'numeric'}, {month: '2-digit'}, {day: 'numeric'}];
    const today = join(new Date(), acc, '-');
    if (wsSocket && state?.groups?.length) {
      state.groups?.forEach((group) => {
        const {group: rbGroup, type: rbType, date} = group;
        const scannerType = rbType ? decodeURIComponent(rbType) : 'tickalert';
        const groupToJoin = {
          group: `${decodeURIComponent(rbGroup)}`,
          scanner: params?.id ?? window.location?.pathname?.replace('/scanners/', ''),
        };
        if (date) {
          groupToJoin[date] = date ?? today;
        }
        // console.log('joining ', rbGroup);
        wsSocket?.emit(`${scannerType}:join`, groupToJoin);
      });
    }
  };

  useEffect(() => {
    if (wsSocket) {
      // console.log('wsSocket', wsSocket);
      wsSocket?.on('connect', () => {
        // console.log('Connected to WS server:', wsSocket?.id, wsSocket?.connected);
        dispatch({type: 'SET_CONNECTED'});
        dispatch({type: 'SET_INITIALIZED'});
      });

      wsSocket?.on('disconnect', (reason) => {
        // console.log('disconnected. Reason:', reason);
        dispatch({type: 'SET_DISCONNECTED'});
        if (reason === 'io server disconnect') {
          // the disconnection was initiated by the server, you need to reconnect manually
          wsSocket?.connect();
          dispatch({type: 'SET_CLIENT_DISCONNECTED'});
        }
      });

      wsSocket?.on('error', (error) => {
        console.error('Server socket.io error:', error);
      });

      wsSocket?.on('connect_error', (error) => {
        console.log('Connect Error:', error);
        dispatch({type: 'SET_INITIALIZED'});
      });

      wsSocket?.on('settings:scanner-access', (scannersData) => {
        dispatch({type: 'SET_SCANNERS', payload: scannersData});
      });

      wsSocket?.on('settings:user', (userData) => {
        dispatch({type: 'SET_USER', payload: userData});
      });

      wsSocket?.on('settings:scanner-access', (scanners) => {
        // console.log('settings:scanner-access', scanners);
      });

      wsSocket?.on(`stateview:update`, (res) => {
        // console.log('update stateview', res);
        const {data, group} = res;
        if (!group || !data) return;
        dispatch({type: 'UPDATE_STATEVIEW', payload: {group, data}});
      });

      wsSocket?.on(`tickalert:update`, (res) => {
        // console.log('update tickalert', res);
        const {data, group} = res;
        if (!group || !data) return;
        dispatch({type: 'UPDATE_TICKALERT', payload: {group, data}});
      });

      wsSocket?.on(`report:update`, (res) => {
        // console.log('update tickalert', res);
        const {data, group} = res;
        if (!group || !data) return;
        dispatch({type: 'UPDATE_REPORT', payload: {group, data}});
      });

      wsSocket?.on(`stateview:refresh`, (res) => {
        // console.log('stateview:refresh', res);
        const {group, data} = res;
        if (!group || !data) return;
        dispatch({type: 'RESET_GROUPS', payload: {group, data}});
      });

      wsSocket?.on(`tickalert:refresh`, (res) => {
        // console.log('tickalert:refresh', res);
        const {group, data} = res;
        if (!group || !data) return;
        dispatch({type: 'RESET_GROUPS', payload: {group, data}});
      });

      wsSocket?.on(`stateview:join`, (res) => {
        // console.log('stateview:join', res);
        const {group, settings, data} = res;
        if (!group || !settings || !data) {
          return;
        }
        dispatch({
          type: 'JOIN_GROUPS',
          payload: {group, settings, data, type: 'stateview'},
        });
        if (settings?.groupInfo?.highlightChange === false) {
          return;
        }
        createStateViewCSS(settings);
      });

      wsSocket?.on(`stateview:delete`, (res) => {
        // console.log('stateview:delete', res);
        const {group, data, type} = res;
        if (!group || !data) {
          return;
        }
        dispatch({
          type: 'STATE_VIEW_DELETE',
          payload: {group, data, type},
        });
      });

      wsSocket?.on(`tickalert:join`, (res) => {
        // console.log('tickalert:join', res);
        const {group, settings, data} = res;
        if (!group || !settings || !data) {
          return;
        }
        dispatch({
          type: 'JOIN_GROUPS',
          payload: {group, settings, data, type: 'tickalert'},
        });
      });

      wsSocket?.on(`report:join`, (res) => {
        // console.log('tickalert:join', res);
        const {group, settings, data} = res;
        if (!group || !settings || !data) {
          return;
        }
        dispatch({
          type: 'JOIN_GROUPS',
          payload: {group, settings, data, type: 'Report'},
        });
      });

      wsSocket?.on(`chart:get`, (res) => {
        const {settings, masterData} = res;
        if (!masterData) {
          return;
        }
        dispatch({
          type: 'SHOW_CHART',
          payload: {symbolData: masterData, chartSettings: settings},
        });
      });

      wsSocket.io.on('reconnection_attempt', () => {
        console.log('reconnection_attempt');
      });

      wsSocket?.io?.on('reconnect', () => {
        console.log('wsSocket.io reconnect');
        reconnectSocket();
      });
    }
    return () => {
      wsSocket?.close();
      dispatch({type: 'SET_CLIENT_DISCONNECTED'});
    };
  }, [wsSocket]);

  useEffect(() => {
    const url = new URL(window.location.href);
    let token = url.searchParams.get('token');
    // if (!token)
    //   token =
    //     'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7InJlZmVycmVyX2lkIjoibW9kMzkyIiwibmFtZSI6IkdhYmUxIiwiZW1haWwiOiJnYWJyaWVsQGdyYXBoZW0uY2EiLCJkaXNwbGF5X2ljb24iOiJodHRwczovL2FwcC5yYWdpbmdidWxsLmNvbS9hc3NldHMvaW1nL2FkbWluX2F2YXRhci5wbmciLCJyb2xlIjoiYWRtaW4iLCJzY2FubmVycyI6eyJhY2Nlc3NpYmxlIjpbInNreW5ldC1keW5hbWljLXdhdGNobGlzdCIsInVudXN1YWwtb3B0aW9ucy1hY3Rpdml0eS1zY2FubmVyIiwiZGFyay1wb29sLXNjYW5uZXIiLCJzcXVlZXplLXNjYW5uZXIiLCJ0cmFkZS1vZi10aGUtZGF5LXNjYW5uZXIiLCJoaWdoLW9jdGFuZS1zY2FubmVyIiwiMzYwLXNjYW5uZXIiLCJwb3N0bWFya2V0LXVwLWdhcHMiLCJqdy1tb21lbnR1bS1zY2FubmVyIiwid2FsbC1zdHJlZXQtYm9va2llLXNjYW5uZXIiLCJzbWFsbC1jYXAtc25pcGVyIiwidHJlbmRpbmctc3RvY2tzIl0sInN1YnNjcmliYWJsZSI6W10sInN1YnNjcmliZWQiOltdfSwiZnJlZV9hY2Nlc3MiOnRydWV9LCJpYXQiOjI2Njg0NTUwMjIsImV4cCI6MjY2ODQ4MzgyMn0.eLAVIjHuq3FcrHhQAk3iVa8ax_aRlvycnqTJ4ivi1E4';
    if (token) {
      localStorage.setItem('scanner-sso', token);
      url.searchParams.delete('token');
      window.history.replaceState({}, window.document.title, url.href);
    } else {
      token = localStorage.getItem('scanner-sso');
    }

    if (!token) {
      console.log('Disconnected. Reason: No user token supplied.');
      dispatch({type: 'SET_INITIALIZED'});
      dispatch({type: 'SET_DISCONNECTED'});
    }
  }, [state.initialized, state.isConnected]);

  const redirectSubscanners = (toJoin, isLive, subscanners) => {
    const url = new URL(window.location.href);
    let token = url.searchParams.get('token');
    // const overrideLive = url.searchParams.get('overrideLive');

    if (!token) {
      token = localStorage.getItem('scanner-sso');
    }
    const scannerId = window.location.pathname.replace('/scanners/', '');
    const urlGroup = url.searchParams.get('group');
    const groupName = urlGroup ?? toJoin[0]?.title;
    const index = subscanners.findIndex((s) => s.name === groupName);
    // const useLive = overrideLive ? overrideLive === 'true' : isLive;
    const useLive = isLive;
    if (index > -1) {
      if (!useLive && subscanners[index]?.live && toJoin?.[index]?.title) {
        // window.location.replace(
        //   `${liveURL}/${scannerId}?group=${encodeURIComponent(toJoin[index].title)}&token=${token}&overrideLive=true`,
        // );
        window.location.replace(
          `${liveURL}/${scannerId}?group=${encodeURIComponent(toJoin[index].title)}&token=${token}`,
        );
        return [];
      }
      if (useLive && !subscanners[index]?.live && toJoin?.[index]?.title) {
        // window.location.replace(
        //   `${delayedURL}/${scannerId}?group=${encodeURIComponent(toJoin[index].title)}&token=${token}&overrideLive=false`,
        // );
        window.location.replace(
          `${delayedURL}/${scannerId}?group=${encodeURIComponent(toJoin[index].title)}&token=${token}`,
        );
        return [];
      }
      const joinURLGroup = toJoin.filter((g) => g.title === groupName);
      return joinURLGroup;
    }
    return toJoin;
 
  };

  useEffect(() => {
    if (state.isConnected && wsSocket && state.scannersData?.length) {
      const scannerId = window.location.pathname.replace('/scanners/', '');
      // console.log(`Firing scanner load event for ${scannerId}`);
      const allGroups = setupGroups(scannerId, state.scannersData);
      let scannerGroups = allGroups;
      let notJoined = allGroups;
      const scannerConfig = state.scannersData?.find((s) => s.slug === scannerId);
      const isLive = JSON.parse(process.env?.REACT_APP_LIVE_DATA ?? 'false');

      if (scannerConfig?.useSubScanners && scannerConfig?.subscanners?.length) {
        const {subscanners} = scannerConfig;
        scannerGroups = scannerGroups
          ?.filter((g) => subscanners.find((s) => s.name === g.title))
          .sort((a, b) => a.title.localeCompare(b.title));
        
        scannerGroups = redirectSubscanners(scannerGroups, isLive, subscanners);
        notJoined = notJoined?.filter((g) => !scannerGroups.find((s) => s.title === g.title)).sort((a, b) => a.title.localeCompare(b.title));
      } else if (
        scannerConfig?.defaultGroup ||
        (scannerConfig?.useSubScanners && !scannerConfig?.subscanners?.length)
      ) {
        const {defaultGroup} = scannerConfig;
        const defaultGroupConfig = scannerConfig?.groups?.find((s) => s?.group === defaultGroup);
        // if (defaultGroupConfig?.report) {
        //   const {report, mainReport, riskReward} = defaultGroupConfig?.report;
        //   const toJoin = [defaultGroup];
        //   if (report) {
        //     toJoin.push(report);
        //   }
        //   if (mainReport) {
        //     toJoin.push(mainReport);
        //   }
        //   if (riskReward) {
        //     toJoin.push(riskReward);
        //   }
        //   scannerGroups = scannerGroups?.filter((g) => toJoin.includes(g?.group));
        //   notJoined = notJoined?.filter((g) => !toJoin.includes(g?.group));
        // } else {
        //   scannerGroups = scannerGroups?.filter((g) => g?.group === defaultGroup);
        //   notJoined = notJoined?.filter((g) => g?.group !== defaultGroup);
        // }
        scannerGroups = scannerGroups?.filter((g) => g?.group === defaultGroup);
        notJoined = notJoined?.filter((g) => g?.group !== defaultGroup);
        console.log('scannerGroups', scannerGroups, notJoined, defaultGroup);
        // if (defaultGroupData) {
        //   dispatch({type: 'SET_DEFAULT_GROUP', payload: {group: defaultGroupData}});
        // }
      }

      // Leave joined groups if any
      if (state.groups?.length) {
        state.groups?.map((group) => {
          if (group.joined) {
            // console.log('leaving ', group.group);
            wsSocket?.emit('group:leave', {group: `${decodeURIComponent(group.group)}`});
          }
        });
      }

      dispatch({type: 'CLEAR_GROUP_COLLECTION'});

      // Join groups if matched
      if (scannerGroups?.length) {
        const acc = [{year: 'numeric'}, {month: '2-digit'}, {day: '2-digit'}];
        const today = join(new Date(), acc, '-');
        // console.log('Attempting to join groups...');
        const joinedGroups = scannerGroups?.map((group) => {
          if (!group.sentJoin) {
            const {group: rbGroup, type: rbType, date} = group;
            const scannerType = rbType ? decodeURIComponent(rbType) : 'tickalert';

            const groupToJoin = {
              group: `${decodeURIComponent(rbGroup)}`,
              scanner: params?.id ?? window.location?.pathname?.replace('/scanners/', ''),
            };

            if (date) {
              groupToJoin[date] = date ?? today;
            }
            // console.log('joining ', rbGroup);
            wsSocket?.emit(`${scannerType}:join`, groupToJoin);
            return {...group, sentJoin: true};
          }
          // console.log('already joined ', group.group);
          return group;
        });
        // console.log('joinedGroups', joinedGroups);
        dispatch({type: 'EMIT_JOIN', payload: {joinedGroups}});

        // Show popup modal 5 seconds after connected
        // setTimeout(() => {
        //   setModalShow(true);
        // }, 5000);

        // run adzerk script after scanner loaded
        // window.runAdzerk();
      }
    }
  }, [state.scannersData?.length]);

  // useEffect(() => {
  //   if (state.isConnected && wsSocket) {
  //     const scannerId = window.location.pathname.replace('/scanners/', '');
  //     // console.log(`Firing scanner load event for ${scannerId}`);
  //     const scannerGroups = setupGroups(scannerId, state.scannersData);

  //     // Leave joined groups if any
  //     if (state.groups?.length) {
  //       state.groups?.map((group) => {
  //         if (group.joined) {
  //           // console.log('leaving ', group.group);
  //           wsSocket?.emit('group:leave', {group: `${decodeURIComponent(group.group)}`});
  //         }
  //       });
  //     }

  //     dispatch({type: 'CLEAR_GROUP_COLLECTION'});

  //     // Join groups if matched
  //     if (scannerGroups?.length) {
  //       const acc = [{year: 'numeric'}, {month: '2-digit'}, {day: 'numeric'}];
  //       const today = join(new Date(), acc, '-');
  //       // console.log('Attempting to join groups...');
  //       const joinedGroups = scannerGroups?.map((group) => {
  //         if (!group.sentJoin) {
  //           const {group: rbGroup, type: rbType, date} = group;
  //           const scannerType = rbType ? decodeURIComponent(rbType) : 'tickalert';

  //           const groupToJoin = {
  //             group: `${decodeURIComponent(rbGroup)}`,
  //           };

  //           if (date) {
  //             groupToJoin[date] = date ?? today;
  //           }
  //           // console.log('joining ', rbGroup);
  //           wsSocket?.emit(`${scannerType}:join`, groupToJoin);
  //           return {...group, sentJoin: true};
  //         }
  //         // console.log('already joined ', group.group);
  //         return group;
  //       });
  //       // console.log('joinedGroups', joinedGroups);
  //       dispatch({type: 'EMIT_JOIN', payload: {joinedGroups}});

  //       // Show popup modal 5 seconds after connected
  //       // setTimeout(() => {
  //       //   setModalShow(true);
  //       // }, 5000);

  //       // run adzerk script after scanner loaded
  //       // window.runAdzerk();
  //     }
  //   }
  // }, [state.scannersData?.length]);

  return (
    <div className={`${theme} flex h-full bg-main-bg`}>
      <Routes>
        <Route
          path="/"
          element={
            <Dashboard theme={theme} setTheme={setTheme} state={state} dispatch={dispatch} wsSocket={wsSocket} />
          }
        >
          <Route index element={<Home />} />
          <Route path="/scanners/:id" element={<Scanners theme={theme} setTheme={setTheme} />} />
        </Route>
        <Route
          path="/reports"
          element={
            <Reports
              user={state.userData}
              reports={state?.scannersData ? state?.scannersData?.filter((sc) => sc?.type === 'reports') : []}
              theme={theme}
              setTheme={setTheme}
            />
          }
        >
          <Route index element={<Reports />} />
          <Route path="/reports/:id" element={<Report />} />
        </Route>
        <Route path="/logout" element={<Logout />} />
        <Route path="/access-denied" element={<AccessDenied />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
