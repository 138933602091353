/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import React, {useEffect, useRef, useState} from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUpRightFromSquare, faLock} from '@fortawesome/free-solid-svg-icons';
import {useParams} from 'react-router-dom';

import {object} from 'prop-types';

const NumAbbr = require('number-abbreviate');

const getBackgroundColor = (rowValue, dataType) => {
  const {displayOptions, key} = dataType;

  if (!displayOptions || !Object.keys(displayOptions ?? {}).length) return {};
  const {formula} = displayOptions;
  let rowStyle = {};
  const rowVal = rowValue[key]?.value ?? rowValue[key];
  if (formula?.length) {
    formula.forEach((f) => {
      const {operations, style} = f;
      operations.forEach((o) => {
        const {operator, value} = o;
        if (operator === '=' && rowVal === value) {
          rowStyle = {...(rowStyle ?? {}), ...(style ?? {})};
        }
      });
    });
  }
  return rowStyle;
};

const hasNumbers = (t) => {
  const regex = /\d/g;
  return regex.test(t);
};

const getValue = (key, formattedObject) => {
  if (!key) return undefined;
  if (key.includes('.')) {
    const [primary, secondary] = key.split('.');
    return formattedObject[primary]?.[secondary]?.value ?? formattedObject[primary]?.[secondary];
  }
  return formattedObject[key]?.value ?? formattedObject[key];
};

const handleRefColumnCalculation = (calculation, formattedObject) => {
  if (!calculation || !formattedObject) return false;

  const {keyA, keyB, keyC, operator} = calculation;
  if (!keyA || !keyB || !operator) return false;

  const valueA = getValue(keyA, formattedObject);
  const valueB = getValue(keyB, formattedObject);
  const valueC = getValue(keyC, formattedObject);

  if (!valueA || !valueB) return false;

  let calcFlag = true;

  switch (operator) {
    case '<':
      calcFlag = valueA < valueB;
      break;
    case '<=':
      calcFlag = valueA <= valueB;
      break;
    case '>':
      calcFlag = valueA > valueB;
      break;
    case '>=':
      calcFlag = valueA >= valueB;
      break;
    case 'contains':
      calcFlag = valueA?.toLowerCase()?.includes(calculation?.value?.toLowerCase());
      break;
    case 'includes':
      calcFlag = valueB.includes(valueA);
      break;
    case '=':
      calcFlag = valueA === valueB;
      break;
    case '!=':
      calcFlag = valueA !== valueB;
      break;
    case 'calc-pct-chg': {
      const {max, maxInclude, min, minInclude, calcOperator} = calculation;
      const priceChange = Number(valueA) - Number(valueB);
      const targetPriceChange = Number(valueC) - Number(valueB);
      const pctChange = (priceChange / targetPriceChange) * 100;

      if (calcOperator === '<') {
        calcFlag = maxInclude ? pctChange <= max : pctChange < max;
      } else if (calcOperator === '>') {
        calcFlag = minInclude ? pctChange >= min : pctChange > min;
      } else if (calcOperator === 'between') {
        calcFlag =
          (minInclude ? pctChange >= min : pctChange > min) && (maxInclude ? pctChange <= max : pctChange < max);
      }
      break;
    }
    default:
      calcFlag = false;
      break;
  }

  return calcFlag;
};

const handleRefColumnSelfCalculation = (calculation, formattedObject) => {
  if (!calculation || !formattedObject) return false;

  const {key, value: formulaValue, operator} = calculation;
  if (!key || (!formulaValue && formulaValue !== 0) || !operator) return false;

  const val = getValue(key, formattedObject);
  if (!val) return false;

  let calcFlag = true;

  switch (operator) {
    case '<':
      calcFlag = val < formulaValue;
      break;
    case '<=':
      calcFlag = val <= formulaValue;
      break;
    case '>':
      calcFlag = val > formulaValue;
      break;
    case '>=':
      calcFlag = val >= formulaValue;
      break;
    case 'contains':
      calcFlag = val?.toLowerCase()?.includes(calculation?.value?.toLowerCase());
      break;
    case 'includes':
      calcFlag = formulaValue.includes(val);
      break;
    case '=':
      calcFlag = val === formulaValue;
      break;
    case '!=':
      calcFlag = val !== formulaValue;
      break;
    default:
      calcFlag = false;
      break;
  }

  return calcFlag;
};

const getStyles = (rowValue, dataType) => {
  const {displayOptions, key} = dataType;
  if (!displayOptions || !Object.keys(displayOptions).length) return {};

  const {formula} = displayOptions;
  let rowStyle = {};
  const rowVal = rowValue[key]?.value !== undefined ? rowValue[key]?.value : rowValue[key];
  if (formula?.length) {
    formula.forEach((f) => {
      let calcFlag = true;
      let orFlag = false;
      const useORFlag = f?.join === 'OR';

      f.operations.forEach((o) => {
        if ((!calcFlag && f?.join === 'AND') || (orFlag && useORFlag)) {
          return;
        }

        const {operator, value: compareValue} = o;
        const updateFlags = (condition) => {
          calcFlag = condition;
          if (useORFlag) orFlag = condition;
        };

        switch (operator) {
          case '<':
            updateFlags(rowVal < compareValue);
            break;
          case '<=':
            updateFlags(rowVal <= compareValue);
            break;
          case '>':
            updateFlags(rowVal > compareValue);
            break;
          case '>=':
            updateFlags(rowVal >= compareValue);
            break;
          case 'contains':
            updateFlags(rowVal?.toLowerCase()?.includes(compareValue?.toLowerCase()));
            break;
          case 'includes':
            updateFlags(compareValue.includes(rowVal));
            break;
          case '=':
            updateFlags(rowVal === compareValue);
            break;
          case '!=':
            updateFlags(rowVal !== compareValue);
            break;
          case 'refcolumn':
            updateFlags(handleRefColumnCalculation(compareValue, rowValue));
            break;
          case 'refcolumn-self':
            updateFlags(handleRefColumnSelfCalculation(compareValue, rowValue));
            break;
          case 'override':
            calcFlag = true;
            if (useORFlag) orFlag = true;
            break;
          default:
            calcFlag = false;
            orFlag = false;
            break;
        }
      });

      if (calcFlag || orFlag) {
        rowStyle = f.style;
      }
    });
  }

  return rowStyle;
};

const displayRowValue = ({
  rowValue,
  dataType,
  dataTypes,
  overrideKey = null,
  openOptions = null,
  optionsColumns = [],
  lockedColumns = [],
  optionsAllowed = true,
  openLockedModal = null,
  trimNumbersFromSymbol = false,
  openReport = null,
  hasReportPopout = false,
  hasReport,
  group,
}) => {
  const {display, key, iconType, type} = dataType;
  const formatPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  if (
    (!rowValue ||
      (!rowValue?.[key]?.value && !rowValue?.[key]) ||
      (overrideKey && !rowValue?.[overrideKey]?.value && !rowValue?.[overrideKey])) &&
    type !== 'pop-out' &&
    type !== 'number'
  )
    return '';

  const {value, style} =
    type !== 'pop-out'
      ? rowValue?.[key] ?? rowValue?.[overrideKey] ?? {value: null, style: null}
      : {value: null, style: null};
  const curVal = value !== undefined ? value : value ?? rowValue?.[key] ?? rowValue?.[overrideKey];

  if (!curVal && display !== 'joined-column' && type !== 'pop-out' && type === 'number' && curVal !== 0) return '';

  // const formatPercentage = new Intl.NumberFormat('en-US', {
  //     style: 'percent',
  // });

  const formatNumber = new Intl.NumberFormat('en-US', {
    currency: 'USD',
  });

  const valueClone = curVal;
  const symbolValue =
    key === 'Symbol' && hasNumbers(curVal) && trimNumbersFromSymbol ? valueClone.split(/[0-9]/)[0] : curVal;

  const baseClass =
    Object.keys(style ?? {})?.length && Object.keys(style ?? {}).includes('backgroundColor')
      ? 'color-item '
      : 'no-color-item ';
  
      if (lockedColumns?.length && lockedColumns?.includes(key)) {
        return (
          <button
            type="button"
            label="open options"
            onClick={() => {
              if (!openLockedModal) return;
              openLockedModal();
            }}
            className="open-options"
          >
            <FontAwesomeIcon icon={faLock} />
          </button>
        );
      }

  switch (display) {
    case 'percentage':
      return curVal && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {`${curVal}%`}
        </span>
      ) : null;
    case 'icon':
      return curVal ? (
        <span className="text-sky-400" style={style ?? {}}>
          <i className={style?.overrideValue ? style?.overrideValue : iconType} />
        </span>
      ) : null;
    case 'custom':
      return curVal ? ( // eslint-disable-next-line
        <span className={baseClass} style={style ?? {}} dangerouslySetInnerHTML={{__html: curVal}} />
      ) : null;
    case 'money-short':
      return curVal && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {`$${NumAbbr(Number(curVal), 2)}`.toUpperCase()}
        </span>
      ) : null;
    case 'number-short':
      return curVal && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {`${NumAbbr(Number(curVal), 2)}`.toUpperCase()}
        </span>
      ) : null;
    case 'money':
      return curVal && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {formatPrice.format(Number(curVal))}
        </span>
      ) : null;
    case 'number':
      return curVal && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {formatNumber.format(Number(curVal))}
        </span>
      ) : null;
    case 'tags':
      return curVal?.length && Array.isArray(curVal) ? (
        <div className="grid auto-cols-max gap-y-2 gap-x-1 justify-center">
          {curVal.map((tag) => (
            <div className="flex items-center justify-center" key={tag}>
              <span
                className={`${
                  Object.keys(style ?? {}).length ? '' : 'no-style-wrap'
                } relative inline mx-auto items-center justify-center rounded-full border border-gray-300 px-2 py-0.5 hover:bg-slate-300`}
              >
                <div
                  className={`${
                    Object.keys(style ?? {}).length ? '' : 'no-style'
                  } text-sm font-medium text-gray-900 tag-name`}
                  style={style ?? {}}
                >
                  {tag}
                </div>
              </span>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <span
            className={`${curVal?.length ? 'border border-gray-300 ' : ''} ${
              Object.keys(style ?? {}).length ? '' : 'no-style-wrap'
            } relative inline mx-auto items-center justify-center rounded-full px-2 py-0.5 hover:bg-slate-300`}
          >
            <div
              className={`${
                Object.keys(style ?? {}).length ? '' : 'no-style'
              } text-sm font-medium text-gray-900 tag-name`}
              style={style ?? {}}
            >
              {curVal}
            </div>
          </span>
        </div>
      );
    case 'bullish-bearish':
      return curVal ? (
        <span
          className={baseClass}
          style={{
            ...(getBackgroundColor(rowValue, dataType) ?? {}),
            ...(style ?? {}),
          }}
        >
          {curVal}
        </span>
      ) : null;
    case 'joined-column': {
      const {columnA, columnB, columnC} = rowValue[key] ?? {columnA: null, columnB: null, columnC: null};
      const columnADataType = dataTypes.find((dt) => dt.key === dataType.columnA);
      const columnBDataType = dataTypes.find((dt) => dt.key === dataType.columnB);

      if (columnA && columnB) {
        if (!columnC) {
          return (
            <span style={{display: 'flex', gap: 5, justifyContent: 'center', alignItems: 'center'}}>
              <span>
                {displayRowValue({
                  rowValue,
                  dataType: columnADataType,
                  dataTypes,
                  overrideKey: dataType.columnA,
                  openOptions,
                  optionsColumns,
                  optionsAllowed,
                  openLockedModal,
                  trimNumbersFromSymbol,
                  openReport,
                  hasReportPopout,
                  hasReport,
                  group,
                })}
              </span>
              <span>
                {displayRowValue({
                  rowValue,
                  dataType: columnBDataType,
                  dataTypes,
                  overrideKey: dataType.columnB,
                  openOptions,
                  optionsColumns,
                  optionsAllowed,
                  openLockedModal,
                  trimNumbersFromSymbol,
                  openReport,
                  hasReportPopout,
                  hasReport,
                  group,
                })}
              </span>
            </span>
          );
        }
        const columnCDataType = dataTypes.find((dt) => dt.key === dataType.columnC);
        if (columnB?.value === 'A+ Setup') {
          return (
            <span style={{display: 'flex', gap: 5, justifyContent: 'center', alignItems: 'center'}}>
              <span>
                {displayRowValue({
                  rowValue,
                  dataType: columnADataType,
                  dataTypes,
                  overrideKey: dataType.columnA,
                  openOptions,
                  optionsColumns,
                  optionsAllowed,
                  openLockedModal,
                  trimNumbersFromSymbol,
                  openReport,
                  hasReportPopout,
                  hasReport,
                  group,
                })}
              </span>
              <span>
                {displayRowValue({
                  rowValue,
                  dataType: columnBDataType,
                  dataTypes,
                  overrideKey: dataType.columnB,
                  openOptions,
                  optionsColumns,
                  optionsAllowed,
                  openLockedModal,
                  trimNumbersFromSymbol,
                  openReport,
                  hasReportPopout,
                  hasReport,
                  group,
                })}
              </span>
            </span>
          );
        }
        return (
          <span style={{display: 'flex', gap: 5, justifyContent: 'center', alignItems: 'center'}}>
            <span>
              {displayRowValue({
                rowValue,
                dataType: columnADataType,
                dataTypes,
                overrideKey: dataType.columnA,
                openOptions,
                optionsColumns,
                optionsAllowed,
                openLockedModal,
                trimNumbersFromSymbol,
                openReport,
                hasReportPopout,
                hasReport,
                group,
              })}
            </span>
            <span>
              {displayRowValue({
                rowValue,
                dataType: columnBDataType,
                dataTypes,
                overrideKey: dataType.columnB,
                openOptions,
                optionsColumns,
                optionsAllowed,
                openLockedModal,
                trimNumbersFromSymbol,
                openReport,
                hasReportPopout,
                hasReport,
                group,
              })}
            </span>
            <span>
              {displayRowValue({
                rowValue,
                dataType: columnCDataType,
                dataTypes,
                overrideKey: dataType.columnC,
                openOptions,
                optionsColumns,
                optionsAllowed,
                openLockedModal,
                trimNumbersFromSymbol,
                openReport,
                hasReportPopout,
                hasReport,
                group,
              })}
            </span>
          </span>
        );
      }
      return null;
    }
    case 'pop-out': {
      const displayOptionsPopout = Object.keys(rowValue ?? {}).some((r) => optionsColumns?.includes(r)) && openOptions;
      return displayOptionsPopout ? (
        !optionsAllowed ? (
          <button
            type="button"
            label="open options"
            onClick={() => {
              if (!openLockedModal) return;
              openLockedModal();
            }}
            className="open-options"
          >
            {' '}
            <FontAwesomeIcon icon={faLock} />
          </button>
        ) : (
          <button
            type="button"
            label="open options"
            onClick={() => {
              openOptions(rowValue);
            }}
            className="open-options"
          >
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </button>
        )
      ) : null;
    }
    default:
      return type === 'number' && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {formatNumber.format(Number(curVal))}
        </span>
      ) : key === 'Symbol' ? (
        <span className="">
          <span style={style ?? {}} className={`${baseClass} symbol-item`}>
            {/* {curVal?.charAt(0) === 'e' ? curVal.substring(1) : curVal} */}
            {symbolValue}
          </span>
          {/* {hasReport ? (
            <button
              type="button"
              label="open options"
              onClick={() => {
                openReport(rowValue);
              }}
              className="open-options ml-1"
            >
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </button>
          ) : null} */}
        </span>
      ) : (
        <span style={style ?? {}} className={`${baseClass + (key === 'Symbol' ? 'symbol-item' : '')}`}>
          {typeof curVal === 'string' && curVal?.includes('A+ Setup') ? 'A+' : curVal}
        </span>
      );
  }
};

const isClickable = (dt, key, row) => dt?.find((d) => d?.key === key) && row[key]?.value;

const ScannerCell = ({
  rowValue,
  dataType,
  dataTypes,
  openOptions,
  optionsColumns,
  row,
  index,
  handleClickSymbol,
  optionsAllowed,
  openLockedModal,
  alignCol,
  trimNumbersFromSymbol,
  hasReportPopout,
  openReport,
  hasReport,
  group,
  lockedColumns,
}) => {
  useEffect(() => {
    if (lockedColumns?.includes(dataType?.key)) {
      return;
    }
    const overrideStyle = getStyles(rowValue, dataType);
    if (Object.keys(overrideStyle ?? {})?.length) {
      const rowElement = document.getElementById(`${rowValue?.Symbol?.value}-${rowValue?._id?.value}`);
      if (rowElement && overrideStyle?.backgroundColor) {
        rowElement.style.backgroundColor = overrideStyle?.backgroundColor;
        rowElement.style.background = overrideStyle?.backgroundColor;
        rowElement.style.setProperty('background-color', overrideStyle?.backgroundColor, 'important');
        rowElement.style.setProperty('background', overrideStyle?.backgroundColor, 'important');
        rowElement.style.setProperty('border-top', '0.5px solid #080b0c', 'important');
        rowElement.style.setProperty('border-bottom', '0.5px solid #080b0c', 'important');
        rowElement.classList.add('override-row-color');
      }
      // row.style.backgroundColor = overrideStyle?.backgroundColor;
    }
  }, [rowValue]);
  return (
    <TableCell
      onClick={() => {
        if (!isClickable(dataTypes, 'ListedExchange', row)) return;
        handleClickSymbol(dataType.key, row?.Symbol?.value);
      }}
      key={`${row ? row._id?.value : ''}-${dataType.key}-${row?.Symbol?.value}-${dataType.type}-${index * 2}`}
      align={alignCol}
      className={`${dataType.convertTo === 'time' ? 'whitespace-nowrap' : 'whitespace-nowrap'} ${dataType.key} ${
        row?.Symbol?.value
      }`}
    >
      <span className="scanner-item">
        {displayRowValue({
          rowValue,
          dataType,
          dataTypes,
          overrideKey: null,
          openOptions,
          optionsColumns,
          optionsAllowed,
          openLockedModal,
          trimNumbersFromSymbol,
          openReport,
          hasReportPopout,
          hasReport,
          group,
          lockedColumns,
        })}
      </span>
    </TableCell>
  );
};
const MemoizedScannerCell = React.memo(ScannerCell, (prev, next) => {
  const rowSame = JSON.stringify(prev.rowValue) === JSON.stringify(next.rowValue);
  const dataTypesSame = JSON.stringify(prev.dataType) === JSON.stringify(next.dataType);
  const hasReportSame = prev.hasReport === next.hasReport;
  // const reportStatus = reportsSame(prev, next);
  // const hasReportSame = reportStatus.value;
  // const dateSame = prev?.group?.selectedDate === next?.group?.selectedDate;
  return rowSame && dataTypesSame && hasReportSame;
});

const ScannerItem = ({
  row,
  dataTypes,
  group,
  getSymbolData,
  openModal,
  alignColumns,
  trimNumbersFromSymbol,
  openOptionsModal,
  openReportModal,
  symbolsWithReports,
  lockedColumns,
  openLockedModal,
}) => {
  const cellRef = useRef(null);
  const [highlightKeys, setHighlightKeys] = useState(Object.keys(row).filter((k) => k.includes('Prev')));
  const params = useParams();

  useEffect(() => {
    if (!highlightKeys?.length) {
      setHighlightKeys(Object.keys(row).filter((k) => k.includes('Prev')));
    }
  }, [row]);

  const highlightRow = (className, prevRowKey, newRowKey) => {
    if (!row[prevRowKey] || !row[newRowKey]) return;
    const prevValue = Number(row[prevRowKey]?.value);
    const newRowVal = Number(row[newRowKey]?.value);
    const animationClassNameIncrease = `${className}Increase`;
    const animationClassNameDecrease = `${className}Decrease`;
    const animationAnimationOutClassName = `${className}AnimationOut`;
    cellRef.current.classList.remove(animationAnimationOutClassName);
    if (prevValue < newRowVal) {
      cellRef.current.classList.add(animationClassNameIncrease);
      setTimeout(() => {
        if (cellRef.current) {
          cellRef.current.classList.remove(animationClassNameIncrease);
          cellRef.current.classList.remove(animationClassNameDecrease);
          if (
            cellRef.current.querySelector(`.${newRowKey} .${newRowKey}`)?.classList?.length &&
            !cellRef.current.querySelector(`.${newRowKey} .${newRowKey}`).classList.contains('backend-text-color')
          ) {
            cellRef.current.classList.add(animationAnimationOutClassName);
          }
        }
        // dispatch({
        //   type: 'GROUP_UPDATED',
        //   payload: {keyId: row?.keyId, group: group.group},
        // });
      }, 1000);
    } else if (prevValue > newRowVal) {
      cellRef.current.classList.add(animationClassNameDecrease);
      setTimeout(() => {
        if (cellRef.current) {
          cellRef.current.classList.remove(animationClassNameIncrease);
          cellRef.current.classList.remove(animationClassNameDecrease);
          if (
            cellRef.current.querySelector(`.${newRowKey} .${newRowKey}`)?.classList?.length &&
            !cellRef.current.querySelector(`.${newRowKey} .${newRowKey}`).classList.contains('backend-text-color')
          ) {
            cellRef.current.classList.add(animationAnimationOutClassName);
          }
        }
        // dispatch({
        //   type: 'GROUP_UPDATED',
        //   payload: {keyId: row?.keyId, group: group.group},
        // });
      }, 1000);
    }
  };

  useEffect(() => {
    if (cellRef.current && row.update) {
      if (!highlightKeys?.length) {
        return;
      }
      const rowKeys = highlightKeys.map((k) => {
        const newKey = k.replace('Prev', '');
        return {
          key: newKey,
          prevKey: k,
        };
      });
      rowKeys.forEach((k) => {
        if (row[k.key] && row[k.prevKey] && row[k.key]?.value !== row[k.prevKey]?.value) {
          highlightRow(k.key, k.prevKey, k.key);
        }
      });
    }
  }, [row]);

  const handleClickSymbol = (key = null, symbol = null) => {
    if (symbol && key === 'Symbol') {
      getSymbolData(symbol, group);
    }
  };

  const openReport = (r) => {
    if (!openReportModal) return;
    openReportModal(r, group, true);
  };

  // const hasReport = () => {
  //   if (!symbolsWithReports?.length) {
  //     // console.log('no symbols with reports', symbolsWithReports);
  //     return false;
  //   }
  //   const curVal = row?.Symbol?.value ?? row?.Symbol;
  //   if (!curVal) {
  //     return false;
  //   }
  //   const valueClone = curVal;
  //   const symbolValue = hasNumbers(curVal) && trimNumbersFromSymbol ? valueClone.split(/[0-9]/)[0] : curVal;
  //   return symbolsWithReports.includes(symbolValue);
  // };

  return (
    <TableRow hover tabIndex={-1} ref={cellRef} id={`${row?.Symbol?.value}-${row?._id?.value}`}>
      {dataTypes
        .filter((col) => !col?.hideColumn)
        .map((dataType, index) => (
          <MemoizedScannerCell
            key={dataType.key}
            rowValue={row}
            dataType={dataType}
            dataTypes={dataTypes}
            row={row}
            index={index}
            handleClickSymbol={handleClickSymbol}
            alignCol={alignColumns}
            trimNumbersFromSymbol={trimNumbersFromSymbol}
            openReport={openReport}
            hasReport={false}
            group={group}
            lockedColumns={lockedColumns}
            openLockedModal={openLockedModal}
          />
        ))}
    </TableRow>
  );
};

const hasReportMemo = (reps, row, trimNumbersFromSymbol) => {
  if (!reps.length) {
    return false;
  }

  const curVal = row?.Symbol?.value ?? row?.Symbol;
  if (!curVal) {
    return false;
  }
  const valueClone = curVal;
  const symbolValue = hasNumbers(curVal) && trimNumbersFromSymbol ? valueClone.split(/[0-9]/)[0] : curVal;

  return reps.includes(symbolValue);
};

export default React.memo(ScannerItem, (prev, next) => {
  const rowSame = JSON.stringify(prev.row) === JSON.stringify(next.row);
  const dataTypesSame = JSON.stringify(prev.dataTypes) === JSON.stringify(next.dataTypes);
  const dateSame = prev?.group?.selectedDate === next?.group?.selectedDate;
  const reportsSame = JSON.stringify(prev?.symbolsWithReports) === JSON.stringify(next?.symbolsWithReports);

  const hasReportPrev = hasReportMemo(prev?.symbolsWithReports, prev?.row, prev?.trimNumbersFromSymbol);
  const hasReportNext = hasReportMemo(next?.symbolsWithReports, next?.row, next?.trimNumbersFromSymbol);
  const hasReportSame = hasReportPrev === hasReportNext;
  return rowSame && dataTypesSame && dateSame && reportsSame && hasReportSame;
});
