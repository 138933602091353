import React from 'react';

const LegendView = ({colorLegend, currentGroupInView}) => {
  if (!colorLegend?.[currentGroupInView?.group]?.length) {
    return null;
  }
  return (
    <div>
      <div className="flex items-start justify-between flex-col gap-2">
        <div className="power-options">
          <h1>{currentGroupInView?.title} - Legend</h1>
        </div>
      </div>
      <div>
        <div className="my-4 text-left">
          <div className="flex items-start justify-evenly gap-4">
            <div>
              <h2 className="text-xl font-bold">Rank</h2>
              <div className="flex flex-col gap-4 justify-start mt-2">
                {/* Preload the legend colors for on the fly coloring */}
                <span className="h-2 w-2 bg-[#eded1c] rounded-full hidden" />
                <span className="h-2 w-2 bg-[#f7070f] rounded-full hidden" />
                <span className="h-2 w-2 bg-[#c21f0a] rounded-full hidden" />
                <span className="h-2 w-2 bg-[#008000] rounded-full hidden" />
                <span className="h-2 w-2 bg-[#00ff00] rounded-full hidden" />
                <span className="h-2 w-2 bg-[#89CFF0] rounded-full hidden" />
                {colorLegend?.[currentGroupInView?.group].map((legendItem) => (
                  <div className="inline-flex items-baseline gap-3 justify-start m-0">
                    <span className={`h-2 w-2 inline-block bg-[${legendItem?.color}] rounded-full`} />
                    <span aria-label={legendItem.label} className="sr-only ">
                      {legendItem.label}
                    </span>
                    <span className="text-gray-600 dark:text-neutral-400">{legendItem.text}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegendView;
