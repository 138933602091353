import React, {useState, useEffect, forwardRef} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUpRightFromSquare, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import CustomInputDate from './CustomInputDate';
import Accordion from '../Accordion/Accordion';
import AllFilters from '../Filters/AllFilters';
import MultiScreenRange from '../Filters/Fields/MultiScreenRange';
import FilterDropdown from '../FilterDropdown/FilterDropdown';
import {ReactComponent as ChevronLeftIcon} from '../../Icons/ChevronLeft.svg';
import {ReactComponent as ChevronRightIcon} from '../../Icons/ChevronRight.svg';
import {ReactComponent as SearchSmIcon} from '../../Icons/SearchSm.svg';
import {join} from '../../Helpers/formatData';

const moment = require('moment');

const Header = ({
  name,
  dispatch,
  filters,
  group,
  page,
  handleChangePage,
  rowsPerPage,
  count,
  wsSocket,
  groupCollection,
  scannerLayout,
  groups,
  multiScreen,
  hideHeader,
  hideSearch,
  hidePagination,
  secondaryHeader,
  useSecondaryHeaderTitle,
  groupNumber,
  hideDateSelectorForGroups,
  openReportModal,
  hasMainReport,
  updatingDate,
  setUpdatingDate,
  currentTab,
  setCurrentTab,
  currentDate,
  openLegendModal,
  colorLegend,
}) => {
  const params = useParams();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState('');
  const [atEnd, setIsAtEnd] = useState(false);
  const [displayLegend, setDisplayLegend] = useState(false);

  useEffect(() => {
    if (!Object.keys(colorLegend ?? {})?.length) {
      if (displayLegend) {
        setDisplayLegend(false);
      }
      return;
    }
    setDisplayLegend(Object.keys(colorLegend)?.includes(group?.groupName));
  }, [group]);
  const disableDays = (current) => {
    const june = moment().set({year: 2022, month: 4, date: 31}).toDate();
    const day = moment(current).day();
    return day !== 0 && day !== 6 && current >= june;
  };

  const formatDate = (date) => {
    const timestamp = Date.parse(date);
    if (Number.isNaN(timestamp)) return new Date();
    const selectedToMomentDay = moment(date, 'YYYY-MM-DD');
    const june = moment().set({year: 2022, month: 4, date: 31});
    const day = selectedToMomentDay.day();
    // If not weekend and after June 31st, return date
    if (day !== 0 && day !== 6 && !moment(selectedToMomentDay).isBefore(june)) {
      return selectedToMomentDay.toDate();
    }
    return new Date();
  };

  const handleSingleDatePickerChange = (newDate) => {
    const acc = [{year: 'numeric'}, {month: '2-digit'}, {day: 'numeric'}];
    const today = join(new Date(), acc, '-');
    const {type: groupType, group: groupName} = group;
    const getGroup = {...group};
    getGroup.date = newDate;
    getGroup.page = 0;
    getGroup.isCurrentDate = newDate === today && getGroup?.showDateSelector;
    const scannerType = groupType ? decodeURIComponent(groupType) : 'tickalert';
    const groupToJoin = {
      group: `${groupName ? decodeURIComponent(groupName) : ''}`,
      date: newDate,
      scanner: params?.id ?? window.location?.pathname?.replace('/scanners/', ''),
    };
    wsSocket?.emit(`${scannerType}:join`, groupToJoin);
    dispatch({type: 'CHANGE_GROUP_DATE', payload: {newDate, group}});
  };

  const changeTab = () => {
    if (currentTab === 0) {
      return;
    }
    if (!setCurrentTab) {
      return;
    }
    setCurrentTab(0);
  };

  const handleMultiGroupDatePickerChange = (newDate) => {
    if (!groups?.length || !newDate) return;
    groups.forEach((gr) => {
      const {type: groupType, group: groupName} = gr;
      if (!groupType || !groupName) return;

      const scannerType = groupType ? decodeURIComponent(groupType) : 'tickalert';
      const groupToJoin = {
        group: `${groupName ? decodeURIComponent(groupName) : ''}`,
        date: newDate,
        scanner: params?.id ?? window.location?.pathname?.replace('/scanners/', ''),
      };
      wsSocket?.emit(`${scannerType}:join`, groupToJoin);
    });
    dispatch({type: 'CHANGE_MULTI_GROUP_DATE', payload: {newDate, groupCollection}});
  };

  const handleDatePickerChange = (date) => {
    if (!group || !wsSocket) return;
    const newDate = moment(date).format('YYYY-MM-DD');
    if (setUpdatingDate) {
      setUpdatingDate(true);
    }
    // changeTab(0);
    if (scannerLayout === 'tabs-layout' || scannerLayout === 'sidebar-layout') {
      handleMultiGroupDatePickerChange(newDate);
    } else {
      handleSingleDatePickerChange(newDate);
    }
  };

  useEffect(() => {
    if (!Object.keys(groupCollection).length || !updatingDate) return;
    const groupCollectionKeys = Object.keys(groupCollection ?? {});
    const filteredKeys = groupCollectionKeys.filter((key) => {
      const gr = groupCollection[key];
      if (!gr) {
        return false;
      }
      if (!gr?.currentDate) {
        if (!gr?.sentDate) {
          console.log('no sent date', gr?.sentDate);
          return false;
        }
        if (gr?.sentDate && !gr?.recievedDate) {
          console.log('no recieved date', gr?.recievedDate);
          return false;
        }
        if (gr?.sentDate && gr?.recievedDate) {
          console.log('sent date', gr?.sentDate, 'recieved date', gr?.recievedDate);
          return gr?.sentDate !== gr?.recievedDate;
        }
      }
      return false;
    });
    console.log('filteredKeys', filteredKeys);
    if (!filteredKeys.length) {
      setUpdatingDate(false);
    }
  }, [groups, groupCollection]);

  useEffect(() => {
    setIsAtEnd(rowsPerPage * (page + 1) > count);
  }, [page, count, rowsPerPage]);

  const hasFilters = () => {
    const withoutSearch = filters?.filter((filt) => filt?.filterable?.type !== 'search');
    const boolAndAdditionalFeatures =
      group?.searchValue?.boolFilters?.filter((field) => field?.fields?.length).length ||
      group?.searchValue?.additionalFilters?.length ||
      group?.searchValue?.bearBullFilters?.length ||
      group?.showBearBullFilter;
    const multiScreenRange =
      multiScreen && withoutSearch?.filter((filter) => filter && filter?.filterable?.type === 'range')?.length;

    return withoutSearch?.length || boolAndAdditionalFeatures || multiScreenRange;
  };

  const handleUpdateMultiSelect = (value, type, field) => {
    const {searchValue: groupSearchValue} = group;
    const newSearchValueCopy = {...groupSearchValue};
    const findFieldToUpdate = newSearchValueCopy.filters.find((filter) => filter?.key === type);

    if (findFieldToUpdate && findFieldToUpdate.value) {
      const updateWith = {
        updatedValue: value
          ? [...findFieldToUpdate.value, field]
          : findFieldToUpdate.value.filter((filter) => filter !== field),
        key: type,
        minMax: null,
        group,
      };
      dispatch({
        type: 'UPDATE_FILTER_VALUES',
        payload: updateWith,
      });
    }
  };

  const openReport = () => {
    openReportModal(null, group, true);
  };

  return (
    <div className="mb-1.5 mx-auto main-group-header">
      <div className="flex justify-between">
        {groupNumber > 1 && !hideHeader && !useSecondaryHeaderTitle ? (
          <div className="flex gap-4 items-center">
            <h2 className="text-header-color font-bold text-[20px] leading-[24px] group-name">{name}</h2>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="flex justify-start gap-9 filter-wrapper">
        {!hideSearch ? (
          <div>
            <div className="flex items-center relative">
              {filters
                ? filters
                    .filter((filt) => filt?.filterable?.type === 'search')
                    .map((filter) => (
                      <input
                        type="text"
                        className="relative rounded-[50px] border-none text-[12px] leading-[15px] text-[#70769B] py-2 pl-9 "
                        placeholder="Filter Tickers"
                        style={{background: 'rgba(148, 196, 242, 0.1)'}}
                        key={filter?.key}
                        onChange={(e) => {
                          dispatch({
                            type: 'UPDATE_FILTER_VALUES',
                            payload: {
                              updatedValue: e.target.value,
                              key: filter?.key,
                              minMax: null,
                              group,
                            },
                          });
                          setSearchValue(e.target.value);
                        }}
                        value={searchValue || ''}
                        name={filter?.key}
                        id={filter?.key}
                      />
                    ))
                : null}
              <SearchSmIcon className="text-[#70769B] absolute left-4 top-1/2 -translate-y-1/2" />
            </div>
          </div>
        ) : null}

        {!hidePagination ? (
          <>
            <button
              type="button"
              disabled={!page}
              onClick={(e) => (page ? handleChangePage(e, page - 1) : null)}
              className={`${!page && 'opacity-60 cursor-default'} flex flex-col items-center pagination-button p-1`}
            >
              <ChevronLeftIcon className="text-[#70769B]" />
              <p className="text-[12px] leading-[15px] text-[#70769B] mt-1">Prev</p>
            </button>
            <button
              type="button"
              disabled={atEnd}
              onClick={(e) => (!atEnd ? handleChangePage(e, page + 1) : null)}
              className={`${atEnd && 'opacity-60 cursor-default'} flex flex-col items-center pagination-button p-1`}
            >
              <ChevronRightIcon className="text-[#70769B]" />
              <p className="text-[12px] leading-[15px] text-[#70769B] mt-1">Next</p>
            </button>
          </>
        ) : null}
        {updatingDate ? (
          <div className="lds-ring-date">
            <div />
            <div />
            <div />
            <div />
          </div>
        ) : group?.selectedDate && group?.showDateSelector && !hideHeader && !hideDateSelectorForGroups ? (
          <div className="flex gap-7 pr-5 date-pick-wrap">
            <div>
              <div className="flex items-center">
                <DatePicker
                  filterDate={disableDays}
                  selected={formatDate(currentDate ?? group?.selectedDate ?? null)}
                  onChange={handleDatePickerChange}
                  customInput={<CustomInputDate />}
                />
              </div>
            </div>
          </div>
        ) : null}

        {secondaryHeader && useSecondaryHeaderTitle ? (
          <div className="flex gap-4 items-center mt-4 justify-start w-full secondary-header">
            <h1 className="text-header-color font-bold text-[24px] leading-[28px]">{secondaryHeader}</h1>
          </div>
        ) : null}

        {hasFilters() ? (
          <Accordion title="Filters">
            <div className="flex items-start flex-col flex-wrap lg:flex-row filter-btn my-1 filter-row gap-x-4 gap-y-2 mx-auto group-header ml-0">
              {group?.searchValue?.filters?.filter((filt) => filt?.filterable?.type !== 'search')?.length ? (
                <AllFilters
                  filters={group?.searchValue?.filters}
                  group={group}
                  handleUpdateMultiSelect={handleUpdateMultiSelect}
                  dispatch={dispatch}
                  multiScreen={multiScreen}
                />
              ) : null}

              {group?.searchValue?.filters?.filter((filter) => filter && filter?.filterable?.type === 'range').length &&
              multiScreen ? (
                <div>
                  <MultiScreenRange
                    group={group}
                    dispatch={dispatch}
                    filters={group?.searchValue?.filters.filter(
                      (filter) => filter && filter?.filterable?.type === 'range',
                    )}
                  />
                </div>
              ) : null}

              {group?.searchValue?.boolFilters?.filter((field) => field?.fields?.length) ||
              group?.searchValue?.additionalFilters?.length ||
              group?.searchValue?.bearBullFilters?.length ||
              group?.showBearBullFilter ? (
                <div className="flex flex-col w-full gap-4">
                  <FilterDropdown
                    group={group}
                    handleUpdateMultiSelect={handleUpdateMultiSelect}
                    additionalFields={[
                      ...(group?.searchValue?.additionalFilters ?? []),
                      ...(group?.searchValue?.boolFilters ?? []),
                    ]}
                    boolFilters={group?.searchValue?.filters?.filter((filter) => filter.type === 'boolean') ?? []}
                    bearBullFilters={group?.searchValue?.bearBullFilters ?? []}
                    showBearBullFilter={group?.showBearBullFilter}
                    activeFilter={group?.activeFilter}
                    dispatch={dispatch}
                    multiScreen={multiScreen}
                  />
                </div>
              ) : null}
            </div>
          </Accordion>
        ) : null}
        {hasMainReport && hasMainReport(group) ? (
          <div className="flex items-center justify-start">
            <button
              type="button"
              label="open options"
              onClick={() => {
                openReport();
              }}
              className="open-options mb-3 flex gap-4 items-center justify-start"
            >
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </button>
          </div>
        ) : null}
        {displayLegend ? (
          <button
            type="button"
            label="open options"
            onClick={() => {
              openLegendModal();
            }}
            className="open-options flex gap-4 items-center justify-start mb-1.5"
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </button>
        ) : null}
        {JSON.parse(process.env?.REACT_APP_LIVE_DATA ?? 'false') ? null : (
          <div className="flex justify-end mx-auto w-full delay-info">
            <p className="delay-info ml-auto mr-4 justify-end">
              <small>45 Minute Delay</small>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Header, (prev, next) => {
  const pageSame = prev.page === next.page;
  const countSame = prev.count === next.count;
  const rowsPerPageSame = prev.rowsPerPage === next.rowsPerPage;
  const filtersSame = JSON.stringify(prev.filters) === JSON.stringify(next.filters);
  const dateSame =
    prev.group.selectedDate === next.group.selectedDate && prev.group.showDateSelector === next.group.showDateSelector;
  const updatingDate = prev.updatingDate === next.updatingDate;
  const currentDateSame = prev.currentDate === next.currentDate;

  return pageSame && countSame && rowsPerPageSame && filtersSame && dateSame && updatingDate && currentDateSame;
});
